import React from "react"
import { Link } from "gatsby"

import Head from "../components/head"
import Layout from "../components/layout"

const NotFound = () => {
  return (
    <Layout>
      <section className="hero is-medium is-primary is-bold">
        <div className="hero-body">
          <div className="container">
            <h1 className="title">Page not found :|: Няма такава страница</h1>
            <h2 className="subtitle">
              <Link to="/">Head home</Link>
            </h2>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NotFound
